import { Injectable } from '@angular/core';
import { StateModel, State } from '../../models/state.model';
import { states } from './states';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private httpClient: HttpClient) { }

  get(): StateModel[] {
    return states;
  }

  getEnabledStates(): Observable<State[]> {
    const url: string = `${environment.apiBaseUrl}/public/countries/us/subdivisions`;
    const params = {
      allow_self_registration: 'true'
    };
    return this.httpClient.get<State[]>(url, { params: params });
  }
}
