import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { System, Attachment } from 'src/app/models';
import moment from 'moment';

@Component({
  selector: 'pr-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Input() selectedSystem$: BehaviorSubject<System>;
  @Input() firstName: String;
  @Input() middleName: String;
  @Input() lastName: String;
  hipaaAttachment: Attachment;
  today = moment().format('MMM DD YYYY, h:mm a');
  zoom = 1;
  showSectionError = false;

  constructor() { }

  ngOnInit(): void {
    this.selectedSystem$.subscribe((system) => {
      this.hipaaAttachment = system?.Attachments?.find(attachment => attachment.TagList.includes('hipaa'));
      this.updateFormValidities(system);
    });
  }

  updateFormValidities(system: System) {
    system?.SelfTestingCenter === true ? this.setRequiredTrueValidator('consent') : this.unSetRequiredValidator('consent');
  }

  setRequiredTrueValidator(controlName: string) {
    this.form.controls[controlName].setValidators(Validators.requiredTrue);
  }

  unSetRequiredValidator(controlName: string) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].reset();
    this.form.controls[controlName].updateValueAndValidity();
  }

  onChangeZoom(amount: number) {
    this.zoom += amount;
  }

  onClickNext() {
    if(this.form.valid) {
      this.next.emit();
    } else {
      this.form.markAllAsTouched();
      this.showSectionError = true;
    }
  }

  onClickPrevious() {
    this.previous.emit();
  }

}
