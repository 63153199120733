import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { System } from "src/app/models";
import moment from 'moment';

@Component({
  selector: "pr-symptom-questionnaire",
  templateUrl: "./symptom-questionnaire.component.html",
  styleUrls: ["./symptom-questionnaire.component.scss"],
})
export class SymptomQuestionnaireComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formsAreFilled: boolean;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Input() selectedSystem$: BehaviorSubject<System>;
  @Input() symptoms: string[];
  @Input() medicalConditions: string[];
  thereIsNoSymptoms = true;
  isThereAnySymptomFreeFacility = false;
  facilityMessage: string;
  minSymptomDay = moment().subtract(28, 'day').toDate();
  maxSymptomDay = new Date();
  showSectionError = false;

  constructor() {}

  ngOnInit(): void {
    this.facilityMessage =
      "Facilities may have symptom-free policies that restrict appointment dates if symptoms or exposure to the virus exist";
    this.selectedSystem$.subscribe((system) => {
      if (system) {
        this.isThereAnySymptomFreeFacility = false;
        system.Facilities.forEach((facility) => {
          if (
            facility.RequiredSymptomFreeDays !== null &&
            facility.RequiredSymptomFreeDays !== 0
          ) {
            this.isThereAnySymptomFreeFacility = true;
          }
        });
        if(this.formsAreFilled) {
          this.onChangeIfTested(this.form.value.q1);
          this.onChangeIfTravelled(this.form.value.travelled);
          this.setNoSymptoms();
        }
      }
    });
  }

  onClickNext() {
    if (this.form.valid) {
      this.next.emit();
    } else {
      this.form.markAllAsTouched();
      this.showSectionError = true;
    }
  }

  onClickPrevious() {
    this.previous.emit();
  }

  onChangeIfTravelled(answer: "Yes" | "No") {
    answer === "Yes"
      ? this.setRequiredValidator("lastSymptomDate")
      : this.unSetRequiredValidator("lastSymptomDate");
  }

  onChangeIfTested(answer: "Yes" | "No") {
    answer === "Yes"
      ? this.setRequiredValidator("q2")
      : this.unSetRequiredValidator("q2");
  }

  onChangeSymptom() {
    this.setNoSymptoms();
    this.form.controls["travelled"].reset();
    this.form.controls["travelled"].setErrors(null);
    this.form.controls["lastSymptomDate"].reset();
    this.form.controls["lastSymptomDate"].setErrors(null);
  }

  setNoSymptoms() {
    this.thereIsNoSymptoms = true;
    const symptomsQuestions = Object.keys(this.form.value).filter(
      (question) =>
        question.startsWith("q") &&
        question !== "q1" &&
        question !== "q2" &&
        question !== `q${this.symptoms.length + 3}`
    );
    symptomsQuestions.forEach((question) => {
      if (this.form.value[question] === "Yes") {
        this.thereIsNoSymptoms = false;
      }
    });
    this.thereIsNoSymptoms === true
      ? this.setRequiredValidator("travelled")
      : this.unSetRequiredValidator("travelled");
    if(this.thereIsNoSymptoms === true) {
      this.unSetRequiredValidator("lastSymptomDate");
    }
  }

  setRequiredValidator(controlName: string) {
    this.form.controls[controlName].setValidators(Validators.required);
  }

  unSetRequiredValidator(controlName: string) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].reset();
    this.form.controls[controlName].updateValueAndValidity();
  }

  isRadioInvalid(controlName: string): boolean {
    return (
      this.form.controls[controlName].touched &&
      this.form.controls[controlName].invalid
    );
  }
}
