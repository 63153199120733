<div [formGroup]="form" class="row no-gutter">

    <mat-form-field appearance="outline" class="col-12 custom--control">
        <mat-label>Date of Birth*</mat-label>
        <input formControlName="dob" matInput [matDatepicker]="dobPicker" (focus)="dobPicker.open()" readonly
          [max]="maxDOB" (dateChange)="checkAgeValidity(form.value.dob)"/>
        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
        <mat-datepicker #dobPicker startView="multi-year"></mat-datepicker>
        <mat-error>Date of Birth is required</mat-error>
    </mat-form-field>

    <mat-hint *ngIf="showAgeHint && !isAgeInvalid" class="col-12 mb-3">
      Patients must be {{selectedSystem?.MinimumDrawAge}} years or older 
      <button mat-icon-button tooltip="TBD" class="help--btn">
        <mat-icon class="help--icon">help</mat-icon>
      </button>
    </mat-hint>

    <pr-message *ngIf="isAgeInvalid; else showInputs" type="error" [content]="ageErrorMessage" class="col-12 mb-3">
    </pr-message>

    <ng-template #showInputs>

      <div class="col-12">
          <div class="row no-gutter">
            <mat-form-field appearance="outline" class="col-8 custom--control pr--3px">
              <mat-label>First Name*</mat-label>
              <input formControlName="first_name" matInput type="text" autocomplete="off"
              capitalizeFirstLetter/>
            <mat-error>{{getErrorMessage('first_name', 'First Name')}}</mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline" class="col-4 custom--control pl--3px">
              <mat-label>MI</mat-label>
              <input formControlName="middle_name" matInput type="text" autocomplete="off" 
                  #middleInitial
                  (keyup)="
                  form.controls.middle_name.setValue(
                  middleInitial.value[0].toUpperCase())"
              />
            <mat-error>Invalid Character</mat-error>
          </mat-form-field>
          </div>
      </div>

      <mat-form-field appearance="outline" class="col-12 custom--control">
          <mat-label>Last Name*</mat-label>
          <input formControlName="last_name" matInput type="text" autocomplete="off"
          capitalizeFirstLetter/>
          <mat-error>{{getErrorMessage('last_name', 'Last Name')}}</mat-error>
      </mat-form-field>

      <div class="col-12">
        <div class="row no-gutter">
          <mat-form-field appearance="outline" class="col-6 custom--control pr--3px">
              <mat-label>Suffix</mat-label>
              <mat-select formControlName="suffix">
                  <mat-option *ngFor="let option of suffixOptions" [value]="option">
                    {{ option }}
                  </mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-6 custom--control pl--3px" 
            [ngClass]="{'error mb-3': form.controls.gender.touched && form.controls.gender.invalid}">
              <mat-label>Gender*</mat-label>
              <mat-select formControlName="gender">
                  <mat-option *ngFor="let option of ['Male', 'Female']" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
            <mat-error>Gender is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12">
        <div class="row no-gutter">

          <mat-form-field appearance="outline" class="col-6 custom--control pr--3px"
              [ngClass]="{'error mb-3': form.controls.race.touched && form.controls.race.invalid}">
              <mat-label>Race*</mat-label>
              <mat-select formControlName="race">
                <mat-option *ngFor="let option of races" [value]="option.Name">
                  {{ option.Name }}
                </mat-option>
              </mat-select>
              <mat-error>Race is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-6 custom--control pl--3px"
              [ngClass]="{'error mb-3': form.controls.ethnicity.touched && form.controls.ethnicity.invalid}">
              <mat-label>Ethnicity*</mat-label>
              <mat-select formControlName="ethnicity">
                <mat-option *ngFor="let option of ethnicities" [value]="option.Name">
                  {{ option.Name }}
                </mat-option>
              </mat-select>
              <mat-error>Ethnicity is required</mat-error>
          </mat-form-field>

        </div>
      </div>

      <mat-form-field appearance="outline" class="col-12 custom--control">
          <mat-label>Address1*</mat-label>
          <textarea rows="1" formControlName="address1" matInput type="text" autocomplete="off">
          </textarea>
          <mat-error>Address1 is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 custom--control">
          <mat-label>Address2</mat-label>
          <textarea rows="1" formControlName="address2" matInput type="text" autocomplete="off"></textarea>
      </mat-form-field>

      <div class="col-12">
        <div class="row no-gutter">
          <mat-form-field appearance="outline" class="col-5 custom--control pr--3px"
              [ngClass]="{'error mb-3': form.controls.city.touched && form.controls.city.invalid}">
              <mat-label>City*</mat-label>
              <input formControlName="city" matInput type="text" autocomplete="off" />
              <mat-error>City is required</mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline" class="col-3 custom--control pr--3px pl--3px"
              [ngClass]="{'error mb-5': form.controls.state.touched && form.controls.state.invalid}">
              <mat-label>State*</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbreviation">
                  {{ state.abbreviation }}
                </mat-option>
              </mat-select>
              <mat-error>State is required</mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline" class="col-4 custom--control pl--3px"
              [ngClass]="{'error mb-5': form.controls.zip.touched && form.controls.zip.invalid}">
              <mat-label>Zip Code*</mat-label>
              <input formControlName="zip" matInput autocomplete="off" />
              <mat-error>{{getErrorMessage('zip', 'Zip Code')}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12">
        <div class="row no-gutter">
          <mat-form-field appearance="outline" class="col-6 custom--control pr--3px"
            [ngClass]="{'error mb-3': form.controls.mobile.touched && form.controls.mobile.invalid}">
            <mat-label>Mobile Phone*</mat-label>
            <input id="mobile" formControlName="mobile" matInput type="tel" autocomplete="off" phoneNumber
            [preValue]="form.controls.mobile.value" [phoneControl]="form.controls.mobile" />
            <mat-error>{{getErrorMessage('mobile', 'Mobile Phone')}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-6 custom--control pl--3px">
            <mat-label>Other Phone</mat-label>
            <input id="home" formControlName="home" matInput type="tel" autocomplete="off" phoneNumber
            [preValue]="form.controls.home.value" [phoneControl]="form.controls.home" />
            <mat-error>{{getErrorMessage('home', 'Other Phone')}}</mat-error>
        </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="outline" class="col-12 custom--control">
          <mat-label>Email*</mat-label>
          <input formControlName="email" matInput type="email" />
          <mat-error>{{getErrorMessage('email', 'Email')}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 custom--control">
          <mat-label>Verify Email*</mat-label>
          <input formControlName="verify_email" matInput type="email" onpaste="return false"/>
          <mat-error>{{getErrorMessage("verify_email", "Verify Email")}}</mat-error>
      </mat-form-field>


      <div class="col-12 mt-3">
          <button *ngIf="!isPatientRedirected" (click)="onClickPrevious()" mat-button color="accent" class="mr-3 px-0">
            Previous
          </button>
          <button 
              (click)="onClickNext()"
              mat-raised-button 
              color="primary"
              class="info-next-btn">
              Next
          </button>
      </div>

      <p *ngIf="showSectionError && form.invalid" class="col-12 alert--text--color mt-3 mb-0">
        One or more required fields are missing.
      </p>

    </ng-template>

</div>
