<div class="app--container">
  <div class="row mx-0">
    <div class="col-12 mb-2">
      <mat-icon svgIcon="logo" class="logo--icon"></mat-icon>
    </div>
    <p class="col-12 title mb-3">{{ title }}</p>
    <h3 class="col-12 page--head">Register</h3>
    <p *ngIf="!loadingStepper" class="col-12 sub--head">
      {{ !(selectedSystem | async)?.Name ? 'Registration' : 'You are registering with ' + (selectedSystem | async).Name + '.'}}
    </p>
  </div>


  <div *ngIf="loadingStepper" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <mat-vertical-stepper *ngIf="!loadingStepper" [linear]="true" #stepper (selectionChange)="onStepChanged($event)">

    <mat-step
      #welcome
      *ngIf="!isPatientRedirected"
      [stepControl]="welcomeFormGroup"
      [optional]="isPatientRedirected"
      [editable]="!isPatientRegistered"
    >
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': welcome.interacted === true
          }"
        >
          Welcome
        </span>
      </ng-template>
      <pr-welcome
        [form]="welcomeFormGroup"
        [formsAreFilled]="formsAreFilled"
        (next)="next()"
        (selectedSystem)="setSelectedSystem($event)"
      >
      </pr-welcome>
    </mat-step>
    <mat-step
      [stepControl]="personalInfoFormGroup"
      [optional]="false"
      #personalInfo
      [editable]="!isPatientRegistered"
    >
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': personalInfo.interacted === true
          }"
        >
          Personal Info
        </span>
      </ng-template>
      <pr-personal-info
        [form]="personalInfoFormGroup"
        (next)="next()"
        (previous)="previous()"
        [selectedSystem$]="getSelectedSystem()"
        [isPatientRedirected]="isPatientRedirected"
      >
      </pr-personal-info>
    </mat-step>
    <mat-step
      *ngIf="showConsentSection"
      [stepControl]="consentFormGroup"
      [optional]="false"
      #consent
      [editable]="!isPatientRegistered"
    >
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': consent.interacted === true
          }"
        >
          Consent
        </span>
      </ng-template>
      <pr-consent
        [form]="consentFormGroup"
        (next)="next()"
        (previous)="previous()"
        [selectedSystem$]="getSelectedSystem()"
        [firstName]="personalInfoFormGroup.value.first_name"
        [middleName]="personalInfoFormGroup.value.middle_name"
        [lastName]="personalInfoFormGroup.value.last_name"
      >
      </pr-consent>
    </mat-step>
    <mat-step
      *ngIf="showBillingSection"
      [completed]="isBillingSectionCompleted"
      [optional]="false"
      #Billing
      [editable]="!isPatientRegistered"
    >
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': Billing.interacted === true
          }"
        >
          Billing
        </span>
      </ng-template>
      <pr-billing
        [form]="billingFormGroup"
        [personalInfoFormGroup]="personalInfoFormGroup"
        (next)="next()"
        (previous)="previous()"
        (paymentDone)="onPaymentDone()"
        (changeBillingOption)="billingSectionValidityChanged()"
        [selectedSystem$]="getSelectedSystem()"
        [paymentIsDone$]="paymentIsDone$"
      >
      </pr-billing>
    </mat-step>
    <mat-step
      [completed]="
        scheduleFormGroup.valid &&
        isPatientRegistered
      "
      [optional]="false"
      #schedule
      [editable]="!isPatientRegistered"
    >
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': schedule.interacted === true
          }"
        >
          {{
            isSchedulingAvailable === true ? "Register & Schedule" : "Register"
          }}
        </span>
      </ng-template>
      <pr-schedule
        [form]="scheduleFormGroup"
        (next)="next()"
        (previous)="previous()"
        (register)="patientRegistered()"
        (scheduled)="patientScheduled()"
        [selectedSystem$]="getSelectedSystem()"
        [forms]="{
          personalInfoFormGroup: personalInfoFormGroup,
          consentFormGroup: consentFormGroup,
          billingFormGroup: billingFormGroup
        }"
        [isPatientRegistered]="isPatientRegistered"
      >
      </pr-schedule>
    </mat-step>
    <mat-step [optional]="false" #confirmation>
      <ng-template matStepLabel>
        <span
          [ngClass]="{
            'mdc--theme--primary--text': confirmation.interacted === true
          }"
        >
          Confirmation
        </span>
      </ng-template>
      <pr-confirmation
        [firstName]="personalInfoFormGroup.value.first_name"
        [middleName]="personalInfoFormGroup.value.middle_name"
        [lastName]="personalInfoFormGroup.value.last_name"
        [selectedSystem$]="getSelectedSystem()"
        (registerAnotherPerson) = "addAnotherPatient()"
      >
      </pr-confirmation>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div class="footer">
  <mat-icon svgIcon="blue-logo" class="logo--icon mb-3"></mat-icon>
  <p>
    Genalyte delivers rapid, actionable results to physicians through
    decentralized laboratory testing, backed by remote Telehealth oversight and
    monitoring.
  </p>
  <p style="font-size: larger;">
    <span class="clickable mr-3" (click)="onOpenFaqModal()">FAQs</span>
    <span *ngIf="supportDetail" class="clickable" (click)="onContactUsModal()">Contact Us</span>
  </p>
  <small class="copyright"
    >Copyright© 2020, Genalyte Inc. All rights reserved.</small
  >
  <small class="version">{{ app.version }}</small>
</div>
 