<div [formGroup]="form" class="row no-gutter">
  <div *ngIf="loadingStates" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <mat-form-field
    *ngIf="!loadingStates"
    appearance="outline"
    class="col-12 mb-3"
  >
    <mat-label>Please select your State</mat-label>
    <mat-select
      formControlName="state"
      (selectionChange)="onChangeState(form.value.state)"
    >
      <mat-option *ngIf="form.value.state" [value]="null">None</mat-option>
      <mat-option *ngFor="let option of states" [value]="option.Code">
        {{ option.Name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="!loadingSystems && (!form.value.state || systems?.length > 0)" 
    class="col-12" 
    [ngClass]="{ 'invalid--radio': isRadioInvalid('system') &&  form.controls.state.valid}">
    <mat-radio-group
      formControlName="system"
      labelPosition="after"
      (change)="onChangeSystem(form.value.system)"
    >
      <mat-radio-button
        *ngFor="let option of systems"
        [value]="option.Id"
        class="w-100"
        color="accent"
      >
        {{ option.Name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="loadingSystems" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <div class="col-12 mt-3">
    <button (click)="onClickNext()" mat-raised-button color="primary" class="welcome-next-btn">
      Next
    </button>
  </div>

  <pr-message
    *ngIf="!loadingSystems && errorMessage"
    class="col-12"
    type="error"
    [content]="errorMessage"
  >
  </pr-message>

  <p
    *ngIf="showSectionError && form.invalid"
    class="col-12 alert--text--color mt-3 mb-0"
  >
    One or more required fields are missing.
  </p>
</div>
