<div mat-dialog-title>
    <div class="d-flex justify-content-between">
        <h3>Contact Details</h3>
    <button mat-icon-button class="close--btn" (click)="onCloseModal()">
        <mat-icon>close</mat-icon>
    </button>
    </div>
</div>

<div mat-dialog-content>
    <h6 class="text-info mb-0">Email:</h6>
    <div class="d-flex align-items-center">
        <h6 class="text-muted mr-2">
            {{supportEmail}}</h6>
        <p (click)="hideCopy()" [cdkCopyToClipboard]="supportEmail">
            <mat-icon class="pointer" *ngIf="!showCopy" aria-hidden="false" aria-label="copy icon">content_copy</mat-icon>
            <small *ngIf="showCopy" class="text-muted  mb-1">copied</small>
        </p>
    </div>
</div>