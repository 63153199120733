import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from 'src/app/models/patient.model';
import { environment } from '../../../environments/environment';
import { PaymentIntent } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private httpClient: HttpClient) {}

  create(patient: Patient) {
    const url: string = `${environment.apiBaseUrl}/chart/patient_registrations`;
    return this.httpClient.post(url, patient);
  }

  patientExists(patient: Patient) {
    const patientParams = {
      first_name: patient.first_name,
      last_name: patient.last_name,
      date_of_birth: patient.dob,
    };
    const url: string = `${environment.apiBaseUrl}/core/patients/exists`;
    return this.httpClient.get(url, { params: patientParams });
  }

  sendPatientToSDBB(sdbbData: {
    first_name: string;
    last_name: string;
    email: string;
    symptom_date?: string;
  }) {
    const SDBB_LINK = environment.production
      ? 'genalyte.redconnecttech.com/#/ScheduleAppointmentGenalyte'
      : 'sdbb.redconnecttech.com/#/ScheduleAppointmentGenalyte';
    let url: string = `https://${SDBB_LINK}?first_name=${sdbbData.first_name}&last_name=${sdbbData.last_name}&email=${sdbbData.email}&symptom_date=`;
    if (sdbbData.symptom_date) {
      url += sdbbData.symptom_date;
    }
    window.location.href = url;
  }

  createPaymentIntent() {
    const data = {
      panel_code: environment.panelCode
    };
    return this.httpClient.post<PaymentIntent>(
      `${environment.apiBaseUrl}/public/payments/payment_intent`,
      data
    );
  }
}
