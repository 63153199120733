import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pr-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() content: string;
  @Input() type: 'success' | 'warn' |'error';

  constructor() { }

  ngOnInit(): void {
  }

}
