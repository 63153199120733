import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import bulletTrain from "bullet-train-client"; //Add this line if you're using bulletTrain via npm
import { environment as ENV } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  hasCaptcha = new BehaviorSubject<boolean>(true);

  constructor() {
    bulletTrain.init({
      environmentID: ENV.bullet_train_id,
      onChange: () => {
        // Occurs whenever flags are changed
        // Check for a feature
        if (bulletTrain.hasFeature("display_recaptcha")) {
          this.hasCaptcha.next(true);
        } else {
          this.hasCaptcha.next(false);
        }
      },
    });
  }
}
