import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State, System } from 'src/app/models';
import { SystemService, StateService } from 'src/app/services';


@Component({
  selector: 'pr-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formsAreFilled: boolean;
  @Output() next = new EventEmitter();
  @Output() selectedSystem = new EventEmitter<System>();
  states: State[];
  systems: System[];
  loadingStates = false;
  loadingSystems = false;
  errorMessage: string;
  showSectionError = false;

  constructor(
    private systemService: SystemService,
    private stateService: StateService
  ) { }

  ngOnInit() {
    this.getStates();
    if(this.formsAreFilled) {
      this.getSystems(this.form.value.state);
    }
  }

  getStates() {
    this.loadingStates = true;
    const sub = this.stateService.getEnabledStates().subscribe(states => {
      this.states = states;
      this.loadingStates = false;
    }, error => {
      this.loadingStates = false;
    }, () => {
      sub.unsubscribe();
    });
  }

  getSystems(state: string) {
    if(state) {
      this.loadingSystems = true;
      this.systemService.getSystemByState(state).subscribe(systems => {
        this.systems = systems;
        systems?.length > 0 ? this.errorMessage = '' : this.errorMessage = 'Oops, System Not Found!';
        if (systems?.length > 0 && systems[0].Options.CustomerSupportDetails) {
          this.systemService.setCustomerSupport(systems[0].Options.CustomerSupportDetails)
        } else {
          this.systemService.setCustomerSupport("")
        }
        if(this.formsAreFilled) {
          this.emitSelectedSystem(this.systems.find(system => system.Id === this.form.value.system));
        }
        this.loadingSystems = false;
      }, error => {
        this.errorMessage = 'No Internet Connection!';
        this.loadingSystems = false;
      });
    } else {
      this.systems = [];
    }
  }

  onChangeState(state: string) {
    this.getSystems(state);
    this.form.controls.system.reset();
    this.emitSelectedSystem(null);
  }

  onChangeSystem(systemId: string) {
    this.emitSelectedSystem(this.systems.find(system => system.Id === systemId))
  }

  emitSelectedSystem(sysyem: System) {
    this.selectedSystem.emit(sysyem);
  }

  onClickNext() {
    if(this.form.valid) {
      this.next.emit();
    } else {
      this.form.markAllAsTouched();
      this.showSectionError = true;
    }
  }

  isRadioInvalid(controlName: string): boolean {
    return (
      this.form.controls[controlName].touched &&
      this.form.controls[controlName].invalid
    );
  }

}
