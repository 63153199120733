import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemService } from 'src/app/services';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'pr-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  supportEmail: string
  showCopy = false

  constructor(public dialogRef: MatDialogRef<ContactUsComponent>,
    public systemService: SystemService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.systemService.getCustomerSupport()
    .subscribe(detail => this.supportEmail = detail)
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  hideCopy() {
    this.showCopy = true
    setTimeout(() => {
      this.showCopy = false
    },600)
  }
}
