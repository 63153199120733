import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, from } from "rxjs";
import { System, Facility, FacilityAddress, Patient } from "src/app/models";
import { SystemService, PatientService, FeatureFlagService } from "src/app/services";
import { concatMap } from "rxjs/operators";
import moment from "moment";
import { environment as ENV } from "../../../../environments/environment";

@Component({
  selector: "pr-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
})
export class ScheduleComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() register = new EventEmitter();
  @Output() scheduled = new EventEmitter();
  @Input() patientObject$: BehaviorSubject<Patient>;
  @Input() forms: {
    personalInfoFormGroup: FormGroup;
    consentFormGroup: FormGroup;
    billingFormGroup: FormGroup;
  };
  @Input() isPatientRegistered = false;
  @Input() selectedSystem$: BehaviorSubject<System>;
  selectedSystem: System;
  isSchedulingAvailable: boolean;
  loadingFacilitiesInfo = false;
  showSectionError = false;
  facilities: Facility[];
  isThereAnySymptomFreeFacility = false;
  env = ENV;
  recaptchaKey = ENV.recaptchaKey;
  showCalendly = false;
  creatingPatientLoader = false;

  constructor(
    private systemService: SystemService,
    private patientService: PatientService,
    public featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.selectedSystem$.subscribe((system) => {
      this.selectedSystem = system;
      this.facilities = system?.Facilities;
      this.getAndSetFacilityAddresses(this.facilities);
      this.checkFacilities(this.facilities);
      this.setRadioGroupValidation(this.isSchedulingAvailable);
    });

    window.addEventListener("message", (event) => {
      if (
        this.isCalendlyEvent(event) &&
        event.data.event === "calendly.event_scheduled"
      ) {
        this.scheduled.emit();
      }
    });

    this.featureFlagService.hasCaptcha.subscribe((newValue) => {
      if (!newValue) {
        // if it should not have re captcha so delete it from formgroup
        this.form.removeControl("recaptcha");
      }
      else // it should have recaptcha so if it is not in formgroup just add it! 
      {
        if (!this.form.get('recaptcha'))
        {
          this.form.addControl('recaptcha', new FormControl('', [
            Validators.required
          ]))
        }
      }
    });

   
  }


  onResister() {
    if (this.form.valid) {
      if (this.isPatientRegistered === false) {
        this.registerAndSchedulePatient();
      } else {
        if (this.isSchedulingAvailable === true && this.form.value.facility.SchedulingLink) {
          this.schedulePatient();
        } else {
          this.next.emit();
        }
      }
    } else {
      this.form.markAllAsTouched();
      this.showSectionError = true;
    }
  }

  registerAndSchedulePatient() {
    let patient: Patient = this.getJsonPatient();
    this.creatingPatientLoader = true;
    this.patientService.create(patient).subscribe(
      (result) => {
        this.register.emit();
        if (
          this.isSchedulingAvailable === true &&
          this.form.value.facility.SchedulingLink
        ) {
          this.schedulePatient();
          this.creatingPatientLoader = false;
        } else {
          setTimeout(() => {
            this.next.emit();
            this.creatingPatientLoader = false;
          }, 300);
        }
      },
      (error) => {
        this.creatingPatientLoader = false;
      }
    );
  }

  schedulePatient() {
    this.openCalendly(this.form.value.facility);
  }

  onClickPrevious() {
    this.previous.emit();
  }

  getAndSetFacilityAddresses(facilities: Facility[]) {
    if (facilities?.length > 0) {
      this.loadingFacilitiesInfo = true;
      let counter = -1;
      const sub = from(facilities)
        .pipe(
          concatMap((facility) => {
            counter++;
            return this.systemService.getFacilityAddress(facility.Id);
          })
        )
        .subscribe(
          (result: FacilityAddress) => {
            this.facilities[counter].Address = result;
          },
          (error) => {
            this.loadingFacilitiesInfo = false;
          },
          () => {
            this.loadingFacilitiesInfo = false;
            sub.unsubscribe();
          }
        );
    }
  }

  checkFacilities(facilities: Facility[]) {
    this.isThereAnySymptomFreeFacility = false;
    this.isSchedulingAvailable = false;
    facilities?.forEach((facility) => {
      if (
        facility.RequiredSymptomFreeDays !== null &&
        facility.RequiredSymptomFreeDays !== 0
      ) {
        this.isThereAnySymptomFreeFacility = true;
      }
      if (facility.SchedulingLink) {
        this.isSchedulingAvailable = true;
      }
    });
  }

  setRadioGroupValidation(isSchedulingAvailable: boolean) {
    isSchedulingAvailable === true
      ? this.setRequiredValidator("facility")
      : this.unSetRequiredValidator("facility");
  }

  getFirstValidDate(days: number): string {
    return moment().add(days, "day").format("MM/DD/yyyy");
  }

  setRequiredValidator(controlName: string) {
    this.form.controls[controlName].setValidators(Validators.required);
  }

  unSetRequiredValidator(controlName: string) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].reset();
    this.form.controls[controlName].updateValueAndValidity();
  }

  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  openCalendly(facility: Facility) {
    document.getElementById("appointmentSection").style.height = "1000px";
    this.showCalendly = true;
    (<any>window).Calendly.initInlineWidget({
      url: facility.SchedulingLink,
      parentElement: document.getElementById("calendly"),
    });
  }

  closeCalendly() {
    this.showCalendly = false;
    document.getElementById("appointmentSection").style.height = "0";
    document
      .getElementById("calendly")
      .removeChild(document.getElementById("calendly").firstElementChild);
    document
      .getElementById("calendly")
      .removeChild(document.getElementById("calendly").lastElementChild);
  }

  getJsonPatient() {
    let patient: Patient = this.getInitialPatient();
    patient.system_id = this.selectedSystem.Id;
    patient.first_name = this.forms.personalInfoFormGroup.value.first_name;
    patient.middle_name = this.forms.personalInfoFormGroup.value.middle_name;
    patient.last_name = this.forms.personalInfoFormGroup.value.last_name;
    patient.suffix = this.forms.personalInfoFormGroup.value.suffix;
    patient.gender = this.forms.personalInfoFormGroup.value.gender;
    patient.dob = moment(this.forms.personalInfoFormGroup.value.dob).format(
      "yyyy-MM-DD"
    );
    patient.race = this.forms.personalInfoFormGroup.value.race;
    patient.ethnicity = this.forms.personalInfoFormGroup.value.ethnicity;
    patient.address.address1 = this.forms.personalInfoFormGroup.value.address1;
    patient.address.address2 = this.forms.personalInfoFormGroup.value.address2;
    patient.address.city = this.forms.personalInfoFormGroup.value.city;
    patient.address.state = this.forms.personalInfoFormGroup.value.state;
    patient.address.zip = this.forms.personalInfoFormGroup.value.zip.toString();
    patient.phone_numbers.mobile = this.forms.personalInfoFormGroup.value.mobile;
    patient.phone_numbers.home = this.forms.personalInfoFormGroup.value.home;
    patient.email = this.forms.personalInfoFormGroup.value.email;
    const billTo = this.forms.billingFormGroup.value.bill_to;
    patient.insurance_policies[0].group_number = this.forms.billingFormGroup.value.insurrance_group_number;
    patient.insurance_policies[0].member_number = this.forms.billingFormGroup.value.insurrance_member_number;
    patient.insurance_policies[0].plan_number =
      patient.insurance_policies[0].member_number;
    patient.insurance_policies[0].bill_to = this.forms.billingFormGroup.value.bill_to;
    patient.insurance_policies[0].name = this.getInsuranceNameByBillTo(billTo);
    if (billTo === "Insurance") {
      patient.insurance_policies[0].provider =
        patient.insurance_policies[0].name;
    }
    if (billTo === "Patient Self Pay") {
      patient.PaymentReceiptToken = "success";
    }
    const hipaa = this.selectedSystem.Attachments?.find((attachment) =>
      attachment.TagList.includes("hipaa")
    );
    if (hipaa && this.forms.consentFormGroup.value.hipaa === true) {
      patient.hipaa_form_id = hipaa.Id;
    }
    return patient;
  }

  getInitialPatient(): Patient {
    let patient: Patient = {
      system_id: "",
      questionnaire: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix: "",
      gender: "",
      PaymentReceiptToken: "",
      dob: "",
      email: "",
      social_security_number: "",
      notes: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        county: "",
        country: "",
      },
      race: "",
      ethnicity: "",
      phone_numbers: {
        mobile: "",
        home: "",
        office: "",
      },
      insurance_policies: [
        {
          name: "",
          plan_number: "",
          group_number: "",
          member_number: "",
          effective_date: "",
          expiration_date: "",
          bill_to: "",
          provider: "",
        },
      ],
    };
    return patient;
  }

  getInsuranceNameByBillTo(billTo: string): string {
    let insuranceName: string;
    if (billTo === "Patient Self Pay") {
      insuranceName = billTo;
    } else if (billTo === "Insurance") {
      insuranceName = this.forms.billingFormGroup.value.insurrance_policy_name;
    } else if (billTo === "Client Bill") {
      insuranceName = this.selectedSystem.Name;
    }
    return insuranceName;
  }
}
