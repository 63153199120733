import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { System } from "src/app/models";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";

const SELF_PAY = "Patient Self Pay";
const INSURANCE = "Insurance";

@Component({
  selector: "pr-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Input() selectedSystem$: BehaviorSubject<System>;
  @Input() paymentIsDone$: BehaviorSubject<boolean>;
  @Input() personalInfoFormGroup: FormGroup;
  @Output() paymentDone = new EventEmitter();
  @Output() changeBillingOption = new EventEmitter();
  selectedSystem: System;
  paymentMethods: string[];
  paymentUrl: string;

  constructor(public sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    this.selectedSystem$.subscribe((system) => {
      this.selectedSystem = system;
      this.setPaymentMethods(system);
    });
  }

  onClickNext() {
    if (this.form.invalid || (this.form.value.bill_to === SELF_PAY && this.paymentIsDone$.value === false)) {
      this.form.markAllAsTouched();
    } else {
      this.next.emit();
    }
  }

  onClickPrevious() {
    this.previous.emit();
  }

  setPaymentMethods(system: System) {
    this.paymentMethods = [];
    if (system) {
      if (system.PaymentTypes.InsuranceBillable === "1") {
        this.paymentMethods.push(INSURANCE);
      }
      if (system.PaymentTypes.SelfPayable === "1") {
        this.paymentMethods.push(SELF_PAY);
      }
    }
  }

  onChanePaymentMethod(paymentMethod: string) {
    if (paymentMethod === INSURANCE) {
      this.setRequiredValidator("insurrance_policy_name");
      this.setRequiredValidator("insurrance_member_number");
      this.setRequiredValidator("insurrance_group_number");
    } else {
      this.unSetRequiredValidator("insurrance_policy_name");
      this.unSetRequiredValidator("insurrance_member_number");
      this.unSetRequiredValidator("insurrance_group_number");
    }
    this.emitBillingSectionChanged();
  }

  emitBillingSectionChanged() {
    this.changeBillingOption.emit();
  }

  isInsuranceSelected(): boolean {
    return this.form.value.bill_to === INSURANCE;
  }

  isSelfPaySelected(): boolean {
    return this.form.value.bill_to === SELF_PAY;
  }

  setRequiredValidator(controlName: string) {
    this.form.controls[controlName].setValidators(Validators.required);
    this.form.controls[controlName].setErrors({required: true});
  }

  unSetRequiredValidator(controlName: string) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].reset();
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].setErrors(null);
  }

  isPaymentMethodInvalid() {
    return (
      this.form.controls.bill_to.touched && this.form.controls.bill_to.invalid
    );
  }

  onEmitPaymentDone() {
    this.paymentDone.emit();
  }
}
