<div class="row no-gutter">
  <!--IF SCHEDULING IS AVAILABLE-->
  <div *ngIf="isSchedulingAvailable" class="col-12">
    <h2 class="primary--text--color">Success</h2>
    <p class="primary--text--color mb-0">
      {{ firstName }} {{middleName}} {{ lastName }}, you are registered for your COVID-19
      Antibody Test.
    </p>
    <p class="primary--text--color">A confirmation email will arrive shortly. Student IDs accepted.</p>

    <p class="mb-0 primary--text--color"><b>Reminders</b></p>
    <ul class="reminders--box primary--text--color">
      <li>Bring a valid Photo ID, Student IDs accepted.</li>
      <li>If you used insurance for payment, bring your insurance card.</li>
      <li>Wear a face covering at all times, including mouth and nose.</li>
      <li class="mdc--theme--error--text">
        <p class="mb-0">
          <b>
            You may not enter this facility with any symptoms or recent
            exposure.
          </b>
        </p>
        <p class="mb-0">
          If you experience any symptoms prior to your appointment, cancel or
          reschedule your appointment and contact your physician or urgent care.
        </p>
      </li>
    </ul>
  </div>

  <!--IF SCHEDULING IS NOT AVAILABLE-->
  <div *ngIf="!isSchedulingAvailable" class="col-12">
    <p class="primary--text--color">
      {{ firstName }} {{middleName}} {{ lastName }}, you are registered for your COVID-19
      Antibody Test.
    </p>
    <h2 class="primary--text--color">SCHEDULE BLOOD DRAW</h2>
    <p class="primary--text--color">
      This testing location schedules appointments with you directly. If you
      have not done so already, call to schedule your blood draw
    </p>

    <div *ngFor="let facility of facilities">
      <h5 class="primary--text--color"><b>{{facility.Name}}</b></h5>
      <p *ngIf="facility.Address?.Address1" class="d-flex primary--text--color">
        <mat-icon color="primary">location_on</mat-icon>
        {{facility.Address.Address1}}
      </p>
      <p *ngIf="facility.Address?.Address2" class="d-flex primary--text--color">
        <mat-icon color="primary">location_on</mat-icon>
        {{facility.Address.Address2}}
      </p>
    </div>

    <p class="mdc--theme--error--text">
      Let this testing site know if you are currently experiencing Covid-19
      related symptoms. They will tell you when you can enter the facility for
      testing.
    </p>

  </div>

  <div *ngIf="loadingFacilitiesInfo" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <div class="col-12 mt-3">
    <button (click)="onDone()" mat-raised-button color="primary" class="mr-3 confirmation-done-btn">
      Done
    </button>
    <button (click)="onRegisterAnotherPerson()" mat-raised-button color="primary" class="confirmation-register-btn">
      Register Another Person
    </button>
  </div>
</div>
