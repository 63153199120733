import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { System, Race, Ethnicity } from 'src/app/models';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SystemService {
  constructor(private httpClient: HttpClient) {}

  $CustomerSupportDetails: BehaviorSubject<string> = new BehaviorSubject("")

  getSystemByState(state: string): Observable<System[]> {
    const url: string = `${environment.apiBaseUrl}/core/systems`;
    const params = {
      allow_self_registration: 'true',
      state: state
    };
    return this.httpClient.get<System[]>(url, { params: params });
  }

  getSystems() {
    const url: string = `${environment.apiBaseUrl}/core/systems`;
    const params = {
      allow_self_registration: 'true'
    };
    return this.httpClient.get<System[]>(url, { params: params });
  }

  setCustomerSupport(value: string) {
    this.$CustomerSupportDetails.next(value)
  }

  getCustomerSupport() {
    return this.$CustomerSupportDetails.asObservable()
  }

  getFacilityAddress(facilityId: string) {
    const url: string = `${environment.apiBaseUrl}/core/facilities/${facilityId}/address`;
    return this.httpClient.get(url);
  }
  
  getRaces(): Observable<Race[]> {
    const url: string = `${environment.apiBaseUrl}/core/races`;
    const params = {
      tag_list: 'prf_races_list'
    };
    return this.httpClient.get<Race[]>(url, { params: params });
  }

  getEthnicities(): Observable<Ethnicity[]> {
    const url: string = `${environment.apiBaseUrl}/core/ethnicities`;
    return this.httpClient.get<Ethnicity[]>(url);
  }
}
