<div [formGroup]="form" class="row mx-0">
  <p class="col-12 mb-3 primary--text--color font-weight-bold">
    We screen for symptoms for your safety and to correlate this novel virus
    with existing health conditions.
  </p>

  <div class="col-12">
    <p
      class="primary--text--color"
      [ngClass]="{ 'invalid--radio': isRadioInvalid('q1') }"
    >
      Have you been tested for Covid-19 through the use of a nasal swab?*
    </p>
    <mat-radio-group
      formControlName="q1"
      labelPosition="after"
      (change)="onChangeIfTested(form.controls.q1.value)"
    >
      <mat-radio-button
        *ngFor="let answer of ['Yes', 'No']"
        [value]="answer"
        color="accent"
        class="inline--radio--button"
      >
        {{ answer }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <hr class="col-12 mt-0" />

  <ng-container *ngIf="form.controls.q1.value === 'Yes'">
    <div class="col-12">
      <p [ngClass]="{ 'invalid--radio': isRadioInvalid('q2') }">
        If yes, what was the result?*
      </p>
      <mat-radio-group formControlName="q2" labelPosition="after">
        <mat-radio-button
          *ngFor="let answer of ['Positive', 'Negative', 'Don’t Know']"
          [value]="answer"
          color="accent"
          class="inline--radio--button"
        >
          {{ answer }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <hr class="col-12 mt-0" />
  </ng-container>

  <p class="col-12 my-3 primary--text--color font-weight-bold">
    Please indicate if you are currently experiencing any of the following
    symptoms:*
  </p>

  <ng-container *ngFor="let symptom of symptoms; let index = index">
    <div class="col-12">
      <p
        class="primary--text--color"
        [ngClass]="{ 'invalid--radio': isRadioInvalid('q' + (index + 3)) }"
      >
        {{ symptom }}
      </p>
      <mat-radio-group
        [formControlName]="'q' + (index + 3)"
        labelPosition="after"
        (change)="onChangeSymptom()"
      >
        <mat-radio-button
          *ngFor="let answer of ['Yes', 'No', 'Not Known']"
          [value]="answer"
          color="accent"
        >
          {{ answer }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <hr class="col-12 mt-0" />
  </ng-container>

  <mat-form-field class="col-12 mb-3">
    <mat-label>Other, specify:</mat-label>
    <input
      matInput
      type="text"
      [formControlName]="'q' + (symptoms.length + 3)"
    />
  </mat-form-field>

  <ng-container *ngIf="thereIsNoSymptoms">
    <div class="col-12">
      <p
        class="primary--text--color"
        [ngClass]="{ 'invalid--radio': isRadioInvalid('travelled') }"
      >
        Have you recently travelled to a highly infected area, been exposed to
        persons with COVID-19 or experienced any of the symptoms listed above?*
      </p>
      <mat-radio-group
        formControlName="travelled"
        labelPosition="after"
        (change)="onChangeIfTravelled(form.controls.travelled.value)"
      >
        <mat-radio-button
          *ngFor="let answer of ['Yes', 'No']"
          [value]="answer"
          color="accent"
        >
          {{ answer }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <hr class="col-12 mt-0" />

    <div *ngIf="form.value.travelled === 'Yes'" class="col-12 my-3">
      <p
        class="primary--text--color"
        [ngClass]="{ 'invalid--radio': isRadioInvalid('lastSymptomDate') }"
      >
       Please provide most recent date for any of these conditions:*
      </p>
      <mat-form-field>
        <mat-label>Date*</mat-label>
        <input
          formControlName="lastSymptomDate"
          matInput
          [matDatepicker]="datePicker"
          (focus)="datePicker.open()"
          readonly
          [min]="minSymptomDay"
          [max]="maxSymptomDay"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error></mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <p
    class="col-12 mb-3 alert--text--color"
    *ngIf="
      isThereAnySymptomFreeFacility &&
      (!thereIsNoSymptoms || form.value.travelled === 'Yes')
    "
  >
    <b>Please note: </b>{{ facilityMessage }}
  </p>

  <p class="col-12 primary--text--color font-weight-bold">
    Pre-existing Medical Conditions*
  </p>

  <ng-container *ngFor="let condition of medicalConditions; let index = index">
    <div class="col-12">
      <p
        class="primary--text--color"
        [ngClass]="{ 'invalid--radio': isRadioInvalid('c' + (index + 1)) }"
      >
        {{ condition }}
      </p>
      <mat-radio-group
        [formControlName]="'c' + (index + 1)"
        labelPosition="after"
      >
        <mat-radio-button
          *ngFor="let answer of ['Yes', 'No', 'Not Known']"
          [value]="answer"
          color="accent"
          class="inline--radio--button"
        >
          {{ answer }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <hr class="col-12 mt-0" />
  </ng-container>

  <mat-form-field class="col-12 mb-3">
    <mat-label>Other, specify:</mat-label>
    <input
      matInput
      type="text"
      [formControlName]="'c' + (medicalConditions.length + 1)"
    />
  </mat-form-field>

  <div class="col-12">
    <button (click)="onClickPrevious()" mat-button color="accent" class="mr-3 px-0">
      Previous
    </button>
    <button (click)="onClickNext()" mat-raised-button color="primary" class="symptom-next-btn">
      Next
    </button>
  </div>

  <p *ngIf="showSectionError && form.invalid" class="col-12 alert--text--color mt-3 mb-0">
    One or more required fields are missing.
  </p>

</div>
