<div mat-dialog-title>
    <button mat-icon-button class="close--btn" (click)="onCloseModal()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <ol>
        <h2 class="modal--title">About Genalyte</h2>
        <li class="title">Who is Genalyte?</li>
        <p>Genalyte is a healthcare analytics and diagnostics company with CAP accredited and CLIA certified
            laboratories based in San Diego, California and Austin, Texas. We have developed and fully validated
            a serology test to detect antibodies to SARS-CoV-2 (the virus that causes COVID-19) and other
            respiratory related viruses.</p>
        <li class="title">How do I know this test is credible?</li>
        <p>Genalyte maintains CAP accredited and CLIA certified laboratories. This means our lab is certified
            by the Center for Medicare and Medicaid Services (CMS) before we can accept any human samples for
            diagnostic testing. Our tests go through rigorous validation efforts to ensure clinical effectiveness.
            Unlike other SARS-CoV-2 tests on the market, the Genalyte SARS-CoV-2 Serology Panel has a very low
            false-positive rate. The accuracy of our test exceeds 99%.</p>
        <h2 class="modal--title">About Antibody Testing</h2>
        <li class="title">What is a serology test?</li>
        <p>Serology tests are blood tests that look for antibodies in your blood. Antibodies are proteins
            made by your body to attack foreign substances, like viruses or bacteria. The process of developing
            antibodies is termed an immune response. If you have been exposed to SARS-CoV-2, your body may
            manufacture antibodies to the virus. The type of antibodies may indicate an active or past infection
            [antibodies may also be present if you receive a vaccine, once available]. A serology test will be able
            to detect those antibodies.</p>
        <li class="title">What is the difference between diagnostic testing for COVID-19 with PCR (polymerase chain
            reaction)
            from nasal or oropharyngeal swabs and serology/antibody testing?
        </li>
        <p>PCR is meant to diagnose a person with an active infection of SARS-CoV-2, whereas antibody testing is
            meant to capture the immune response to the virus to determine whether or not the person was previously
            infected (or vaccinated once a vaccine becomes available).</p>
        <li class="title">What is a typical antibody response to a virus, with a serology test?</li>
        <p>A human immune response to a virus has multiple components, one of which is the development of
            antibodies to the virus. Antibodies are proteins, produced by cells in your body, whose primary purpose
            is to help your body fight foreign invaders (such as viruses or bacteria). Typically, the first
            antibody to appear is IgM (the Ig stands for immunoglobulin and the M is the type of antibody).
            It usually appears in the first 3-7 days of an infection, peaks at about 2 weeks, and then declines
            over the next 3-4 weeks. Immunoglobulin G (IgG) lags behind IgM production by a few days, ramps up over
            the next 7-14 days, and then persists in the body for months to years. The presence of IgG is usually
            considered protective against the viral invader and also suggests you are less likely to be re-infected
            with that virus.</p>
        <p>The timing of these antibody responses help physicians understand where you are in your infection. In
            general, the presence of IgM suggests you are early in the immune reaction to the virus, and if you have
            IgM antibodies without IgG antibodies, you may be in the early phase of an active infection.
            Conversely, if you have IgG antibodies without IgM antibodies, you are generally considered to be later
            in an infection process. For most viruses, you may be infectious in the early phase. As IgG antibodies
            develop, you become less able to spread the virus to others. By the time IgG is the predominant
            antibody in your system, for most viruses, you are no longer considered able to spread the disease.</p>
        <p>There is much we do not know about the SARS-CoV-2 virus. We don’t know if IgM, IgG, and the ability to
            spread the infection are the same as other viruses. Some data would suggest that symptomatic SARS-CoV-2
            patients have the ability to spread the virus longer than other viruses. Thus, if you are positive for
            antibodies to SARS-CoV-2 and have questions about your ability to spread the virus, you should make an
            appointment with your primary care physician, or call a telemedicine facility, and discuss this with a
            health care provider. They can assess your situation and determine if additional testing is needed.</p>
        <h2 class="modal--title">About Genalyte’s Testing Service</h2>
        <li class="title">Can I register my dependents?</li>
        <p>We recognize the importance of family testing. At this time, we are only able to test individuals 13 years old and above. We are looking at ways to expand testing and will update the FAQs if the age limitation changes. In addition, we will soon be updating the registration process to allow adding dependents below the age of 13 to a parent or guardian account.</p>
        <li class="title">What is the cost of the test?</li>
        <p>There is no out of pocket cost for the test with valid health insurance. There is no out of pocket cost for the test with valid health insurance. For cash pay, consumers pay $65, a discounted rate from our list price of $84.</p>
        <li class="title">If I have insurance, is the test covered at no expense to me?</li>
        <p>Yes. All COVID-19 testing for insured patients is mandated by federal law
            (Families First bill and CARES Act) to be covered at no cost to the patient. All comprehensive private
            health insurance plans must cover COVID-19 testing needed to detect or diagnose coronavirus and the
            administration of that testing without cost-sharing or barriers (such as prior authorization or other
            medical management requirements). This coverage requirement began on March 18, 2020
            (Families First Act), was extended March 25, 2020 (CARES Act) and remains in effect while there is a
            declared public health emergency (as defined under federal law). Any issues with private health
            insurance will be addressed by Genalyte with no cost sharing to the patient.</p>
        <li class="title">If I pay out of pocket for this test but have insurance through a commercial payor or the
            government, can I be reimbursed?
        </li>
        <p>If you have insurance through a commercial payor or through the government, all COVID-19 testing is
            covered at no cost to the patient. Please work directly with your insurance carrier to submit a claim
            for reimbursement. Your insurance carrier will be able to specify what supporting information is
            needed.</p>
        <li class="title">If I do not have insurance, do I have to pay anything for this test?</li>
        <p>If you do not have insurance through a commercial payor or the government, certain states are covering
            the cost of the test through the state Medicaid program. Please check with your state to see if state
            Medicaid is covering the cost and what supporting information is needed for reimbursement. If the state
            is not covering the cost for individuals without insurance, the cost of the test is $65.</p>
        <li class="title">How long will it take to get results?</li>
        <p>Typically, we are able to process samples and return results within 24-48 hours. In certain
            circumstances, results may exceed 48 hours. You will be notified via secure email when your results are
            ready to review.</p>
        <h4 class="modal--title">How to Interpret Your Result Report</h4>
        <li class="title">If I have the presence of SARS-CoV-2 antibodies, what does it mean?</li>
        <p>The presence of antibodies to SARS-CoV-2 suggests that you have been exposed to the SARS-CoV-2 virus
            and your body is mounting an immune response. This may occur if you had an active infection
            (with symptoms, such as fever, sore throat, tiredness, etc.) or did not have symptoms (asymptomatic).</p>
        <li class="title">If I have the presence of SARS-CoV-2 IgM and/or IgG antibodies, what should I do?</li>
        <p>The presence of SARS-CoV-2 antibodies indicates that you have developed an immune response to the virus.
            The type of antibodies that are in your blood may indicate the timing of your immune response.
            The presence of IgM antibodies suggests you are early in your immune response. It is currently unclear,
            if you have IgM antibodies, if you can spread the infection to others.`,
            `The presence of IgG antibodies indicates you are later in your immune response to SARS-CoV-2. If you did
            not have symptoms, the presence of IgG suggests past exposure to SARS-CoV-2. The presence of IgG
            antibodies to a virus generally indicates an immune status and that you are no longer infectious.
            However, the SARS-CoV-2 virus has not been around long enough to establish that correlation.</p>
        <p>A positive result does not provide a definitive diagnosis of COVID-19 (the clinical disease caused by
            SARS-CoV-2). If you have an early immune response, we recommend that you schedule an appointment with
            your primary care physician or telemedicine facility for a full evaluation. A trained physician will be
            able to determine if additional testing is needed and recommend the appropriate course of treatment to
            address your symptoms.</p>
        <p>If you have the presence of IgG antibodies and you have not had symptoms for at least 14 days, you likely
            have developed an appropriate immune response to SARS-CoV-2 and are unlikely to have an active infection.
            If you have questions about your immune status, we recommend that you schedule an appointment with your
            primary care physician or telemedicine facility for a full evaluation. A trained physician will be able
            to determine your status and if additional testing is needed for you.`,
            `If you have the presence of IgG antibodies, you may qualify to be a COVID-19 convalescent plasma (CCP)
            donor. CCP is being investigated in the treatment of COVID-19 patients. For more information, please
            connect with your local blood bank.</p>
        <li class="title">If I have antibodies to SARS-CoV-2, am I protected from catching the virus again?</li>
        <p>To typical viral infections, the development of viral specific IgG antibodies confers a degree of
            protection from reinfection. IgM antibodies are typically the first antibody to appear in an immune
            reaction, peak at a few days to a week, and then decline (typically gone by 3-4 weeks). IgG antibodies
            appear a few days after IgM antibodies and persist for months to years. It is likely that the same
            situation will pertain to SARS-CoV-2 infections. However, the SARS-CoV-2 virus has not been around long
            enough to know if the IgG antibodies to the virus will persist in blood, and if the presence of IgG
            antibodies to SARS-CoV-2 will protect you from reinfection.</p>
        <p>Thus, even if you test positive for antibodies to SARS-CoV-2 you should continue to follow all public
            health guidelines recommended to prevent COVID-19 infection such as; physical distancing, washing your
            hands and avoid touching your eyes, nose and mouth. More information about COVID-19 can be found on the
            Center for Disease Control and Prevention website
            <a href="https://www.cdc.gov/coronavirus/2019-nCoV/index.html"
               target="_blank">(https://www.cdc.gov/coronavirus/2019-nCoV/index.html)</a>
        </p>
        <li class="title">If I have an absence of SARS-CoV-2 IgM and IgG antibodies, what should I do?</li>
        <p>An absence of SARS-CoV-2 antibodies suggests that you have not been exposed to the SARS-CoV-2 virus, or that
            you have not developed an immune response to the virus. If you had upper respiratory symptoms, it may be
            that you are reacting to a different virus. Depending on the length of time you have had symptoms, your body
            may just be starting to develop antibodies to SARS-CoV-2. You should continue to follow all public health
            guidelines recommended to prevent COVID-19 infection such as; physical distancing, washing your hands and
            avoid touching your eyes, nose and mouth. More information about COVID-19 can be found on the Center for
            Disease Control and Prevention website
            <a href="https://www.cdc.gov/coronavirus/2019-nCoV/index.html"
               target="_blank">(https://www.cdc.gov/coronavirus/2019-nCoV/index.html)</a>
        </p>
        <p>Please be aware that antibodies to COVID-19 can take more than two weeks to show up in your system. We
            recommend that you wait at least two full weeks after symptoms have resolved to be tested with a serology
            test to effectively understand SARS-CoV-2 viral exposure.</p>
        <li class="title">Can I have a positive result without the presence of IgM and IgG antibodies to SARS-CoV-2?
        </li>
        <p>Yes. Based on our machine learning algorithm, you may have an immune response to SARS-CoV-2, however your
            individual antibody tests are not reactive. This likely means that you have a weak antibody response to
            SARS-CoV-2 from either a mild or asymptomatic infection or are very early in the course of your response. In
            this case, a PCR/antigen test could be useful, so please discuss this with your physician.</p>
        <li class="title">If I have an indeterminate result, what does it mean?</li>
        <div>An indeterminate result means that the test was unable to confirm the presence(positive) or absence
            (negative) of antibodies in your blood to SARS-CoV-2. There are common reasons why you may have received an
            indeterminate result:
            <br>
            <br>
            <ul>
                <li>There could be cross-reactivity with another virus, not SARS-CoV-2, that makes confirming the
                    presence or absence of SARS-CoV-2 in your blood difficult. The Genalyte test significantly reduces
                    cross-reactivity by testing for other viruses like Influenza (Flu), MERS and SARS- CoV-1 (the
                    original SARS coronavirus). It is also possible that your blood showed cross-reactivity with a virus
                    not on our panel.
                </li>
                <br>
                <li>You may be early in your immune response to SARS-CoV-2 so there is not a sufficient amount of IgM or
                    IgG antibodies to provide either a positive or negative result.
                </li>
            </ul>
        </div>
        <li class="title">If I have an indeterminate result, what should I do?</li>
        <p>If you are early in your immune response, the level of antibodies in your blood may be too low to be
            accurately measured. In that case, it is recommended that you wait 7-14 days and retest. If you are early in
            your response, in 7-14 days your antibodies will most likely increase and become detectable.</p>
        <li class="title">What are benign coronaviruses and what does it mean if I am reactive to a benign
            coronavirus?
        </li>
        <p>A benign coronavirus is a virus that is in the same family of viruses as SARS-CoV-2, but causes non-life
            threatening symptoms that are usually more similar to a common cold. If you have an immune response to a
            benign coronavirus, but your results for SARS-CoV-2 are negative, it is possible to produce some antibodies
            that are cross-reactive with SARS-CoV-2.</p>
        <li class="title"> What is cross-reactivity and what does it mean if I have a cross-reactive result?</li>
        <p>Sometimes your body produces antibodies that show strong reactivity to a specific virus and variable
            reactivity to other viruses. If that happens, you may have reactivity that doesn't matchthe typical response
            to SARS-COV-2 infection. The presence of such cross-reacting antibodies can make the test difficult to
            interpret.</p>
        <li class="title">What happens if my test comes back with No Result?</li>
        <p>Uncommonly, we are not able to return a result due to a sample collection issue, a transportation issue or an
            interfering substance. We recognize that this is an inconvenience and we apologize. We are committed to
            getting a result in your hand and encourage you to schedule another appointment at no additional cost.
            Simply go to www.genalytecovid19.com, click on the registration link, type in your name and you will be
            prompted to schedule another appointment.</p>
        <h2 class="modal--title">Important Notifications</h2>
        <li class="title">Who do I contact if I have questions?</li>
        <p>
            Please direct all questions to <span style="color: #3d85c6;">covidsupport@genalyte.com.</span> We
            will ensure your inquiry is addressed by the appropriate individuals within 24 hours.
        </p>
        <li class="title">What are my rights under HIPAA?</li>
        <p>Please review Genalyte’s
            <span (click)="navigateTo('https://genalyte.webflow.io/notice-of-privacy-practices')"
                  style="color: #3d85c6; cursor: pointer">Notice of Privacy Practices.</span>
        </p>
        <li class="title">What are the FDA’s required disclosures for the SARS-CoV-2 serology test?</li>
        <p>This test has not been reviewed by the FDA. Negative results do not rule out SARS-CoV-2 infection,
            particularly in those who have been in contact with the virus. Follow-up testing with a molecular diagnostic
            should be considered to rule out infection in these individuals. Results from antibody testing should not be
            used as the sole basis to diagnose or exclude SARS-CoV-2 infection or to inform infection status. Positive
            results may be due to past or present infection with non-SARS- CoV-2 coronavirus strains, such as
            coronavirus HKU1, NL, OC43, or 229E.</p>
    </ol>
</div>