<div *ngIf="!(paymentIsDone$ | async)" class="mb-3">
  <ngx-stripe-card containerClass="stripe--card"
    [options]="cardOptions"
    [elementsOptions]="elementsOptions">
  </ngx-stripe-card>
  <button *ngIf="!loading" mat-raised-button color="accent" class="mt-3 w-100" (click)="pay()">
    PAY
  </button>
</div>
<pr-message
  *ngIf="(paymentIsDone$ | async) && !loading"
  type="success"
  content="Payment Successfully Processed."
>
</pr-message>
<pr-message
  *ngIf="errorMessage && !(paymentIsDone$ | async) && !loading"
  type="error"
  [content]="errorMessage"
>
</pr-message>

<div *ngIf="loading" class="w-100">
  <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
</div>
