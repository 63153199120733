import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CapitalizeFirstLetterDirective } from './directives/capitalize-first-letter.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WelcomeComponent } from './components/steps/welcome/welcome.component';
import { PersonalInfoComponent } from './components/steps/personal-info/personal-info.component';
import { MessageComponent } from './components/message/message.component';
import { SymptomQuestionnaireComponent } from './components/steps/symptom-questionnaire/symptom-questionnaire.component';
import { ConsentComponent } from './components/steps/consent/consent.component';
import { BillingComponent } from './components/steps/billing/billing.component';
import { FaqModalComponent } from './components/faq-modal/faq-modal.component';
import { ScheduleComponent } from './components/steps/schedule/schedule.component';
import { ConfirmationComponent } from './components/steps/confirmation/confirmation.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { StripeComponent } from './components/stripe/stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment as ENV } from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    FaqModalComponent,
    ContactUsComponent,
    PhoneNumberDirective,
    CapitalizeFirstLetterDirective, 
    WelcomeComponent, 
    PersonalInfoComponent, 
    MessageComponent, 
    SymptomQuestionnaireComponent, 
    ConsentComponent, 
    BillingComponent,
    ScheduleComponent,
    ConfirmationComponent,
    ContactUsComponent,
    StripeComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PdfViewerModule,
    TooltipModule.forRoot(),
    NgxStripeModule.forRoot(ENV.production ? ENV.stripeProdKey : ENV.stripeTestKey),
  ],
  exports: [MaterialModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
