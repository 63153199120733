import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { version } from "./../../package.json";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { environment as ENV } from "./../environments/environment";
import { filter } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { System, Patient } from "./models";
import { BehaviorSubject } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { FaqModalComponent } from './components/faq-modal/faq-modal.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SystemService } from './services';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

declare var gtag: any;
declare var fbq: any;

const SELF_PAY = "Patient Self Pay";
const INSURANCE = "Insurance";
const CLIENT_BILL = "Client Bill";

@Component({
  selector: "pr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [DatePipe],
})
export class AppComponent implements OnInit {
  title = "COVID-19 Antibody Testing";
  handler: any = null;
  app = {} as any;
  GOOGLE_ID: string;
  symptoms: string[];
  medicalConditions: string[];
  showConsentSection = false;
  showBillingSection = false;
  formsAreFilled = false;
  isBillingSectionCompleted = false;
  paymentIsDone$ = new BehaviorSubject<boolean>(false);
  isSchedulingAvailable = false;
  isPatientRegistered = false;
  isPatientScheduled = false;
  isPatientRedirected = false;
  loadingStepper = true;
  url: string;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  selectedSystem = new BehaviorSubject<System>(null);
  welcomeFormGroup: FormGroup;
  personalInfoFormGroup: FormGroup;
  consentFormGroup: FormGroup;
  billingFormGroup: FormGroup;
  scheduleFormGroup: FormGroup;

  patientObject = new BehaviorSubject<Patient>(null);
  supportDetail: String;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private systemService: SystemService
  ) {
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag("config", this.GOOGLE_ID, {
        page_path: event.urlAfterRedirects,
      });
      this.url = event.urlAfterRedirects;
      if (this.url) {
        this.getUrlParams();
      }
    });
  }

  ngOnInit() {
    this.app.version = `version: ${version}`;
    this.setSymptomsAndConditions();
    this.initialForms();
    this.GOOGLE_ID = this.getGtagEnvironment();  // dynamic env assignment
    this.formsAreFilled = localStorage.getItem("forms") ? true : false;
    if (this.formsAreFilled) {
      this.getFormsFromLocalStorage();
    }
    this.setValuesBasedOnSelectedSystem();
    this.systemService.getCustomerSupport()
      .subscribe(detail => {
        this.supportDetail = detail;
      })
  }

  fbTrackRegister() {
    if (ENV.envName === "pilot") {
      fbq("track", "Purchase");
    } else return;
  }

  getGtagEnvironment() {
    if (ENV.envName === "pilot") {
      return ENV.google_analytics.GOOGLE_ID_PILOT;
    } else if (ENV.envName === "z") {
      return ENV.google_analytics.GOOGLE_ID_Z;
    } else {
      return ENV.google_analytics.GOOGLE_ID_QA; // qa/dev defaults to this ID
    }
  }

  initialForms() {
    this.welcomeFormGroup = new FormGroup({
      state: new FormControl("", Validators.required),
      system: new FormControl("", Validators.required),
    });
    this.personalInfoFormGroup = new FormGroup({
      dob: new FormControl("", Validators.required),
      first_name: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern("[a-zA-Z ' -`]*"),
      ]),
      middle_name: new FormControl("", [Validators.pattern("[a-zA-Z ]")]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern("[a-zA-Z ' -`]*"),
      ]),
      suffix: new FormControl(""),
      gender: new FormControl("", Validators.required),
      race: new FormControl("", Validators.required),
      ethnicity: new FormControl("", Validators.required),
      address1: new FormControl("", Validators.required),
      address2: new FormControl(""),
      city: new FormControl("", Validators.required),
      state: new FormControl("", Validators.required),
      zip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]*"),
        Validators.maxLength(5),
      ]),
      mobile: new FormControl("", [
        Validators.required,
        Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)
      ]),
      home: new FormControl(
        "", Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)),
      email: new FormControl("", [Validators.required, Validators.email]),
      verify_email: new FormControl("", [
        Validators.required,
        Validators.email,
        this.verifyEmailValidator("email"),
      ]),
    });
    this.consentFormGroup = new FormGroup({
      hipaa: new FormControl(false),
    });
    this.billingFormGroup = new FormGroup({
      bill_to: new FormControl("", Validators.required),
      insurrance_policy_name: new FormControl(""),
      insurrance_member_number: new FormControl(""),
      insurrance_group_number: new FormControl("")
    });
    this.scheduleFormGroup = new FormGroup({
      facility: new FormControl(''),
      recaptcha: new FormControl('', Validators.required)
    });
  }

  setSelectedSystem(selectedSystem: System) {
    this.selectedSystem.next(selectedSystem);
  }

  getSelectedSystem(): BehaviorSubject<System> {
    return this.selectedSystem;
  }

  next() {
    this.stepper.next();
  }

  previous() {
    this.stepper.previous();
  }

  verifyEmailValidator(confirmEmailInput: string) {
    let confirmEmailControl: FormControl;
    let emailControl: FormControl;

    return (control: FormControl) => {
      if (!control.parent) {
        return null;
      }

      if (!confirmEmailControl) {
        confirmEmailControl = control;
        emailControl = control.parent.get(confirmEmailInput) as FormControl;
        emailControl.valueChanges.subscribe(() => {
          confirmEmailControl.updateValueAndValidity();
        });
      }

      if (
        emailControl.value?.toLocaleLowerCase() !==
        confirmEmailControl.value?.toLocaleLowerCase()
      ) {
        return {
          verifyemail: true,
        };
      }
      return null;
    };
  }

  setSymptomsAndConditions() {
    this.symptoms = [
      "Fever over 100.4F (38C)",
      "Subjective fever (felt feverish)",
      "Chills",
      "Headache",
      "Dizziness",
      "Pharyngalgia (Sore throat)",
      "Cough (new onset or worsening of chronic cough)",
      "Rhinorrhea (runny nose)",
      "Dyspnea (difficult or labored breathing)",
      "Myalgia (muscle aches)",
      "Nausea or vomiting",
      "Abdominal pain",
      "Diarrhea (≥3 loose/looser than normal stools/24hr period)",
      "Loss of taste or smell",
    ];
    this.medicalConditions = [
      "Chronic Obstructive Pulmonary Disease (asthma/emphysema)",
      "Coronary Heart/Cardiovascular Disease",
      "Diabetes",
      "Hypertension",
      "Cerebrovascular Disease",
      "Chronic Kidney Disease",
      "Chronic Liver disease",
      "Immunocompromised Condition",
      "Neurologic/neurodevelopmental/intellectual disability",
      "Anorexia (lack or loss of appetite for food)",
      "If female, currently pregnant",
      "Current smoker",
      "Former smoker",
    ];
  }

  setValuesBasedOnSelectedSystem() {
    this.getSelectedSystem().subscribe((system) => {

      this.checkConsentAndHipaa(system);
      this.checkFacilities(system);

      this.showBillingSection =
        system?.PaymentTypes.InsuranceBillable === "1" ||
        system?.PaymentTypes.SelfPayable === "1";

      if (!this.formsAreFilled) {
        if (
          system?.PaymentTypes.InsuranceBillable === "0" &&
          system?.PaymentTypes.SelfPayable === "0" &&
          system?.PaymentTypes.ClientBillable === "1"
        ) {
          this.billingFormGroup.controls.bill_to.setValue(CLIENT_BILL);
        } else {
          this.billingFormGroup.controls.bill_to.setValue("");
        }
      }
    });
  }

  getUrlParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (this.url.includes("slug")) {
        this.findRedirectedSystem(params["slug"]);
      } else {
        this.loadingStepper = false;
      }
    });
  }

  removePaymentParams() {
    this.router.navigate([], {
      queryParams: {
        payment: null,
      },
      queryParamsHandling: "merge",
    });
  }

  getFormsFromLocalStorage() {
    const forms = JSON.parse(localStorage.getItem("forms"));
    this.welcomeFormGroup.setValue(forms.welcomeFormGroup);
  }


  onPaymentDone() {
    this.paymentIsDone$.next(true);
    this.billingSectionValidityChanged();
  }

  billingSectionValidityChanged() {
    this.isBillingSectionCompleted =
      this.billingFormGroup.valid &&
      !(this.billingFormGroup.value.bill_to === SELF_PAY &&
        this.paymentIsDone$.value === false);
  }

  onOpenFaqModal(): void {
    this.dialog.open(FaqModalComponent, {
      height: '500px',
      width: '700px',
    });
  }

  onContactUsModal(): void {
    this.dialog.open(ContactUsComponent, {
      height: '200px',
      width: '500px',
    });
  }

  checkConsentAndHipaa(system: System) {
    this.showConsentSection =
        system?.Attachments?.find((attachment) =>
          attachment.TagList.includes("hipaa")
        )
        ? true
        : false;
  }

  checkFacilities(system: System) {
    this.isSchedulingAvailable = false;
    system?.Facilities.forEach(facility => {
      if (facility.SchedulingLink) {
        this.isSchedulingAvailable = true;
      }
    });
  }

  patientRegistered() {
    this.isPatientRegistered = true;
    localStorage.clear();
  }

  addAnotherPatient() {
    const forms: any = {};
    forms.welcomeFormGroup = this.welcomeFormGroup.value;
    localStorage.setItem("forms", JSON.stringify(forms));
    location.reload();
  }

  patientScheduled() {
    this.isPatientScheduled = true;
    setTimeout(() => {
      this.stepper.next();
    }, 300);
  }

  findRedirectedSystem(systemSlug: string) {
    if (systemSlug) {
      this.systemService.getSystems().subscribe(
        (systems) => {
          const system = systems?.find((system) => system.Slug === systemSlug);
          if (system) {
            this.setSelectedSystem(system);
            this.isPatientRedirected = true;
          }
          this.loadingStepper = false;
        },
        (error) => {
          this.loadingStepper = false;
        }
      );
    }
  }

  onStepChanged(event: StepperSelectionEvent): void {
    if (event.selectedIndex < event.previouslySelectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }
}
