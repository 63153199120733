import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import moment from "moment";
import { Race, Ethnicity, StateModel, System } from "src/app/models";
import { StateService, SystemService } from "src/app/services";
import { BehaviorSubject, zip } from "rxjs";

@Component({
  selector: "pr-personal-info",
  templateUrl: "./personal-info.component.html",
  styleUrls: ["./personal-info.component.scss"],
})
export class PersonalInfoComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Input() selectedSystem$: BehaviorSubject<System>;
  @Input() isPatientRedirected: boolean;
  selectedSystem: System;
  showAgeHint = false;
  maxDOB = moment().subtract(1, "day").toDate();
  races: Race[] = [];
  ethnicities: Ethnicity[] = [];
  states: StateModel[];
  suffixOptions: string[];
  ageErrorMessage: string;
  isAgeInvalid = false;
  showSectionError = false;

  constructor(
    private systemService: SystemService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.getInfo();
    this.selectedSystem$.subscribe((system) => {
      this.selectedSystem = system;
      const minDrawAge = this.selectedSystem?.MinimumDrawAge;
      this.showAgeHint =
        minDrawAge && minDrawAge !== 0 && minDrawAge !== "0" ? true : false;
      this.ageErrorMessage = `Genalyte testing centers are currently not accepting patients younger than ${this.selectedSystem?.MinimumDrawAge} years. Please refer to the FAQ section for further information or questions. Thank you.`;
      this.checkAgeValidity(this.form.value.dob);
    });
  }

  checkAgeValidity(dob?: Date) {
    if (dob && this.selectedSystem && this.showAgeHint) {
      const age = moment().diff(moment(dob), "years");
      this.isAgeInvalid = age < +this.selectedSystem?.MinimumDrawAge;
      if (this.isAgeInvalid) {
        this.form.controls.dob.setErrors({
          matDatepickerMin: true,
        });
      }
    } else {
      this.isAgeInvalid = false;
      this.form.controls.dob.updateValueAndValidity();
    }
  }

  getInfo() {
    zip(
      this.systemService.getRaces(),
      this.systemService.getEthnicities()
    ).subscribe((result) => {
      this.races = result[0]?.sort((a, b) =>
        a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0
      );
      this.ethnicities = result[1];
    });
    this.states = this.stateService.get();
    this.suffixOptions = ["Jr.", "Sr.", "I", "II", "III", "IV"];
  }

  onClickNext() {
    if (this.form.valid) {
      this.next.emit();
    } else {
      this.form.markAllAsTouched();
      this.showSectionError = true;
    }
  }

  onClickPrevious() {
    this.previous.emit();
  }

  getErrorMessage(formControlName: string, inputName: string): string {
    if (
      this.form.controls[formControlName] &&
      this.form.controls[formControlName].errors
    ) {
      if (this.form.controls[formControlName].errors.required) {
        return `${inputName} is required`;
      } else if (this.form.controls[formControlName].errors.minlength) {
        const requiredLength = this.form.controls[formControlName].errors
          .minlength.requiredLength;

        return `Minimum of ${requiredLength} characters`;
      } else if (this.form.controls[formControlName].errors.maxlength) {
        const requiredLength = this.form.controls[formControlName].errors
          .maxlength.requiredLength;

        return `Maximum of ${requiredLength} characters`;
      } else if (this.form.controls[formControlName].errors.email) {
        return `Invalid email`;
      } else if (this.form.controls[formControlName].errors.verifyemail) {
        return `Email address mismatch, please try again.`;
      } else if (this.form.controls[formControlName].errors.pattern) {
        if (
          formControlName === "first_name" ||
          formControlName === "last_name"
        ) {
          return `Enter a valid ${inputName}`;
        } else if (formControlName === "middle_name") {
          return `${inputName} should be one character`;
        } else if (formControlName === "mobile" || formControlName === "home") {
          return `${inputName} should be 10 digits`;
        } else if (formControlName === "zip") {
          return `Invalid zip code`;
        }
      }
    }
  }
}
