<div [formGroup]="form" class="row mx-0">

  <ng-container *ngIf="hipaaAttachment">
    <div class="col-12 hipaa--wrapper mt-3">
      <h5 class="mt-3"><b>HIPAA Authorization</b></h5>
      <pdf-viewer
        class="hipaa"
        [src]="hipaaAttachment.FileUrl"
        [zoom]="zoom"
        [original-size]="false"
      >
      </pdf-viewer>
    </div>

    <div class="col-12">
      <button mat-icon-button (click)="onChangeZoom(-0.1)">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-icon-button (click)="onChangeZoom(0.1)">
        <mat-icon>zoom_in</mat-icon>
      </button>
    </div>

    <mat-checkbox class="col-12" formControlName="hipaa" color="accent">
      I authorize. (optional)
    </mat-checkbox>

    <div *ngIf="form.value.hipaa === true" class="col-12">
      <p class="mb-0"><b>Electronic Signature</b></p>
      <p class="primary--text--color">
        {{ firstName }} {{ middleName }} {{ lastName }}, {{ today }}
      </p>
    </div>
  </ng-container>

  <div class="col-12 mt-3">
    <button (click)="onClickPrevious()" mat-button color="accent" class="mr-3 px-3">
      Previous
    </button>
    <button (click)="onClickNext()" mat-raised-button color="primary" class="consent-next-btn">
      Next
    </button>
  </div>

  <p *ngIf="showSectionError && form.invalid" class="col-12 alert--text--color mt-3 mb-0">
    One or more required fields are missing.
  </p>

</div>
