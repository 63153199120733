<div [formGroup]="form" class="row no-gutter">
  <label class="col-12 mt-1">
    <span [ngClass]="{ 'invalid--radio': isPaymentMethodInvalid() }"
      >Bill to*</span
    >
  </label>
  <mat-radio-group
    formControlName="bill_to"
    class="col-12 mb-3"
    labelPosition="after"
    (change)="onChanePaymentMethod(form.value.bill_to)"
  >
    <mat-radio-button
      *ngFor="let paymentMethod of paymentMethods"
      [value]="paymentMethod"
      [ngClass]="{'insurance--label': paymentMethod === 'Insurance'}"
      color="accent"
      [disabled]="paymentMethod === 'Insurance' && (paymentIsDone$ | async)"
    >
        <p class="mb-0">{{ paymentMethod === 'Patient Self Pay' ? 'Self Pay $65' : paymentMethod }}</p>
        <small *ngIf="paymentMethod === 'Insurance'" class="secondary--text--color">No cost with valid insurance</small>

    </mat-radio-button>
  </mat-radio-group>

  <ng-container *ngIf="isInsuranceSelected()">
    <mat-form-field appearance="outline" class="col-12 custom--control" 
      [ngClass]="{'error': form.controls.insurrance_policy_name.touched && form.controls.insurrance_policy_name.invalid}">
      <mat-label>Carrier*</mat-label>
      <input
        formControlName="insurrance_policy_name"
        matInput
        type="text"
        autocomplete="off"
        (keyup)="emitBillingSectionChanged()"
      />
      <mat-error>Carrier is required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-12 custom--control"
      [ngClass]="{'error': form.controls.insurrance_member_number.touched && form.controls.insurrance_member_number.invalid}">
      <mat-label>Policy ID*</mat-label>
      <input
        formControlName="insurrance_member_number"
        matInput
        type="text"
        autocomplete="off"
        (keyup)="emitBillingSectionChanged()"
      />
      <mat-error>Policy ID is required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-12 custom--control"
      [ngClass]="{'error': form.controls.insurrance_group_number.touched && form.controls.insurrance_group_number.invalid}">
      <mat-label>Group Number*</mat-label>
      <input
        formControlName="insurrance_group_number"
        matInput
        type="text"
        autocomplete="off"
        (keyup)="emitBillingSectionChanged()"
      />
      <mat-error>Group Number is required</mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="isSelfPaySelected()" class="col-12">
    <pr-stripe
      [personalInfoFormGroup]="personalInfoFormGroup"
      (paymentDone)="onEmitPaymentDone()"
      [paymentIsDone$]="paymentIsDone$">
    </pr-stripe>
  </div>

  <div class="col-12 mt-3">
    <button (click)="onClickPrevious()" mat-button color="accent" class="mr-3 px-0">
      Previous
    </button>
    <button (click)="onClickNext()" mat-raised-button color="primary" class="billing-next-btn">
      Next
    </button>
  </div>
</div>
