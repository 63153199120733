<div [formGroup]="form" class="row no-gutter">
  <ng-container *ngIf="!loadingFacilitiesInfo">
    <mat-radio-group
      *ngIf="!showCalendly"
      formControlName="facility"
      labelPosition="after"
      class="col-12"
      (change)="captchaR?.reset()"
      [ngClass]="{
        'error--box': showSectionError && form.controls.facility.invalid
      }"
    >
      <mat-radio-button
        *ngFor="let facility of facilities"
        [value]="facility"
        color="accent"
        [disabled]="!isSchedulingAvailable"
        [ngClass]="{ 'hidden--radio': !isSchedulingAvailable }"
      >
        <div class="facility--card mb-3 w-100">
          <p class="facility--name">{{ facility.Name }}</p>
          <p *ngIf="facility.Address?.Address1" class="facility--address">
            {{ facility.Address.Address1 }}
          </p>
          <p *ngIf="facility.Address?.Address2" class="facility--address">
            {{ facility.Address.Address2 }}
          </p>
          <div *ngIf="facility.RequiredSymptomFreeDays" class="facility--alert">
            <p>
              This facility has a {{ facility.RequiredSymptomFreeDays }} day
              symptom free policy. Please schedule on
              {{ getFirstValidDate(+facility.RequiredSymptomFreeDays) }} or
              after.
            </p>
            <button
              mat-icon-button
              [tooltip]="tooltipContent"
              class="help--btn"
              container="body"
              placement="left"
            >
              <mat-icon class="help--icon">help</mat-icon>
            </button>
          </div>
          <p *ngIf="!isSchedulingAvailable && !facility.SchedulingLink">
            This facility schedules appointments directly with you.
          </p>
        </div>
        <ng-template #tooltipContent>
          <b>Symptom Free Policy: </b>This facility will deny entry or service
          if you have had symptoms, potential exposure to someone with symptoms,
          or have traveled to a high risk area in the last
          {{ facility.RequiredSymptomFreeDays }} days.
        </ng-template>
      </mat-radio-button>
    </mat-radio-group>
    <div
      class="col-12 recaptcha--wrapper"
      *ngIf="(featureFlagService.hasCaptcha | async) && !(isSchedulingAvailable && !form.value.facility)"
    >
      <re-captcha
        #captchaR
        formControlName="recaptcha"
        [siteKey]="recaptchaKey"
      ></re-captcha>
    </div>

    <div id="appointmentSection" class="col-12">
      <p *ngIf="showCalendly" class="calendly--close" (click)="closeCalendly()">
        X
      </p>
      <div id="calendly" style="height: 100%"></div>
    </div>
  </ng-container>

  <div *ngIf="loadingFacilitiesInfo" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <div *ngIf="!creatingPatientLoader && !showCalendly" class="col-12 mt-3">
    <button
      *ngIf="!isPatientRegistered"
      (click)="onClickPrevious()"
      mat-button
      color="accent"
      class="mr-3 px-3"
    >
      Previous
    </button>
    <button
      (click)="onResister()"
      mat-raised-button
      color="primary"
      class="schedule-next-btn"
    >
      {{ isPatientRegistered === false ? "Register" : "Schedule" }}
    </button>
  </div>

  <div *ngIf="creatingPatientLoader" class="col-12">
    <mat-progress-bar class="my-3" color="primary" mode="indeterminate">
    </mat-progress-bar>
  </div>

  <p
    *ngIf="showSectionError && form.invalid"
    class="col-12 alert--text--color mt-3 mb-0"
  >
    One or more required fields are missing.
  </p>
</div>
