export const environment = {
  production: true,
  apiBaseUrl: 'https://api.pilot.genalyte.com',
  envName: 'pilot',
  systemId: '7b3bb2b0-0df6-4892-900d-3c72a852fb1f',
  recaptchaKey: '6Ld5DugUAAAAANc_gIig6YzyyzWdK_h0RfYz4K_z',
  callbackUrl: 'https://covid-19.pilot.genalyte.com',
  stripeTestKey: 'pk_test_2eBUbqDlmia0ycUM1EtBY5mL', // pk_test_2eBUbqDlmia0ycUM1EtBY5mL
  stripeProdKey: 'pk_live_vXqBFKjbZMRwkHEyX73mW6Re',
  prod_sku_product: 'sku_H6uSEdvXhkMaWu',
  test_sku_product: 'sku_H6feIfIgaqGQiV',
  google_analytics: {
    GOOGLE_ID_PILOT: 'UA-145088452-6',
    GOOGLE_ID_QA: 'UA-145088452-3',
    GOOGLE_ID_Z: 'UA-145088452-7',
  },
  san_diego_sys_id: '01a5cc80-b768-44f4-9227-4411cd52be18',
  gulf_coast_sys_id: 'd8c20538-b136-4a1d-a5d2-0a468d2a624b',
  panelCode: 'GMA0500',
  bullet_train_id: 'Azvyt8nwcJhCCZBrS8R4hE',
};
