import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { System, Facility, FacilityAddress } from "../../../models";
import { concatMap } from "rxjs/operators";
import { SystemService } from "../../../services";

@Component({
  selector: 'pr-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() selectedSystem$: BehaviorSubject<System>;
  @Input() firstName: string;
  @Input() middleName: String;
  @Input() lastName: string;
  @Output() done = new EventEmitter();
  @Output() registerAnotherPerson = new EventEmitter();
  loadingFacilitiesInfo = false;
  isSchedulingAvailable = false
  facilities: Facility[];

  constructor(private systemService: SystemService) { }

  ngOnInit(): void {
    this.selectedSystem$.subscribe((system) => {
      this.facilities = system?.Facilities;
      this.checkFacilities(this.facilities);
      if(this.isSchedulingAvailable === false) {
        this.getAndSetFacilityAddresses(this.facilities);
      } else {
        this.facilities = null;
      }
    });
  }

  getAndSetFacilityAddresses(facilities: Facility[]) {
    if (facilities?.length > 0) {
      this.loadingFacilitiesInfo = true;
      let counter = -1;
      const sub = from(facilities)
        .pipe(
          concatMap((facility) => {
            counter++;
            return this.systemService.getFacilityAddress(facility.Id);
          })
        )
        .subscribe(
          (result: FacilityAddress) => {
            this.facilities[counter].Address = result;
          },
          (error) => {
            this.loadingFacilitiesInfo = false;
          },
          () => {
            this.loadingFacilitiesInfo = false;
            sub.unsubscribe();
          }
        );
    }
  }

  checkFacilities(facilities: Facility[]) {
    this.isSchedulingAvailable = false;
    facilities?.forEach((facility) => {
      if (facility.SchedulingLink) {
        this.isSchedulingAvailable = true;
      }
    });
  }

  onDone() {
    location.reload();
  }

  onRegisterAnotherPerson() {
    this.registerAnotherPerson.emit()
  }

}
