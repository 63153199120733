import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pr-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.scss']
})
export class FaqModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FaqModalComponent>) { }

  ngOnInit() {
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  navigateTo(link) {
    window.open(link, '_blank')
  }

}
