import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[capitalizeFirstLetter]'
})
export class CapitalizeFirstLetterDirective {

  constructor(private elementRef: ElementRef) { }

  @HostListener('keyup') onKeyPress() {
    this.elementRef.nativeElement.value = this.capitalizeFirstLetter(this.elementRef.nativeElement.value);
  }

  private capitalizeFirstLetter(text: string): string {
    if (text) {
      let firstLetter = text.substr(0, 1).toUpperCase();
      let rest = text.substr(1).toLowerCase();
      return firstLetter + rest;
    } else {
      return '';
    }
  }

}
