import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({ selector: '[phoneNumber]' })
export class PhoneNumberDirective {

  private _phoneControl: AbstractControl;
  private _preValue: string;
  private _ID: string;

  @Input()
  set phoneControl(control: AbstractControl) {
    this._phoneControl = control;
  }
  @Input()
  set preValue(value: string) {
    this._preValue = value;
  }

  private sub: Subscription;

  constructor(private elem: ElementRef, private renderer:Renderer2) { }

  ngOnInit() {
    this.phoneValidate();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  phoneValidate() {

    this._ID = `#${this.elem.nativeElement.id}`

    this.sub = this._phoneControl.valueChanges.subscribe(data => {
      let preInputValue: string = this._preValue;
      let newVal = data.replace(/\D/g, '');
      let start = this.renderer.selectRootElement(this._ID).selectionStart;
      let end = this.renderer.selectRootElement(this._ID).selectionEnd;
      
      if (data.length < preInputValue.length) {
        if (newVal.length == 0) {
          newVal = '';
        }else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        this._phoneControl.setValue(newVal, { emitEvent: false });
        this.renderer.selectRootElement(this._ID).setSelectionRange(start, end);
      } else {
        let removedD = data.charAt(start);
        if (newVal.length == 0) {
          newVal = '';
        }
         else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }
        //check typing whether in middle or not
        //in the following case, you are typing in the middle
        if (preInputValue.length >= start) {
          if (removedD == '-') {
            start = start + 1;
            end = end + 1;
          }
          this._phoneControl.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement(this._ID).setSelectionRange(start, end);
        } else {
          this._phoneControl.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement(this._ID).setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
        }
      }
    });
  }
}
