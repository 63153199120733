import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { switchMap } from "rxjs/operators";

import { StripeService, StripeCardComponent } from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { PatientService } from "src/app/services";
import {FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: "pr-stripe",
  templateUrl: "./stripe.component.html",
  styleUrls: ["./stripe.component.scss"],
})
export class StripeComponent implements OnInit {
  @Input() personalInfoFormGroup: FormGroup;
  @Input() paymentIsDone$: BehaviorSubject<boolean>;
  @Output() paymentDone = new EventEmitter();
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#0f3b63",
        color: "#0f3b63",
        fontWeight: "300",
        fontFamily: '"Ubuntu, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };
  errorMessage: string;
  loading = false;

  constructor(
    private patientService: PatientService,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
  }

  pay(): void {
    this.loading = true;
    const info = this.personalInfoFormGroup.value;
    this.patientService
      .createPaymentIntent()
      .pipe(
        switchMap((pi: any) => {
          return this.stripeService.confirmCardPayment(pi.ClientSecret, {
            payment_method: {
              card: this.card.element,
              billing_details: {
                name: `${info.first_name} ${info.middle_name} ${info.last_name}`,
                email: info.email,
                phone: info.mobile,
              },
            },
          })
        }
        )
      )
      .subscribe((result) => {
        if (result.error) {
          this.errorMessage = result.error.message;
        } else {
          if(result.paymentIntent.status === "succeeded") {
            this.paymentDone.emit();
          }
        }
        this.loading = false;
      });
  }
}
