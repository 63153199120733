<div>
    <mat-card 
        class="text-center" 
        [ngClass]="{
            'success-message': type === 'success', 
            'warn-message': type === 'warn', 
            'error-message': type === 'error'}">
        {{content}}
    </mat-card>
</div>
